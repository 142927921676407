import { Component, OnInit, ElementRef } from '@angular/core';
import { VidModalErrorService } from './vid-modal-error.service';

@Component({
  selector: 'app-vid-modal-error',
  templateUrl: './vid-modal-error.component.html',
  styleUrls: ['./vid-modal-error.component.scss']
})
export class VidModalErrorComponent implements OnInit {
  constructor(private elRef: ElementRef, private vidModalErrorService: VidModalErrorService) {}

  description = '';
  title = 'general.errorModal';
  ngOnInit() {
    this.vidModalErrorService.openModal.subscribe(val => {
      val.title ? this.title = val.title : '';
      this.description = val.description;
      this.elRef.nativeElement.classList.add('show');
    });

    this.vidModalErrorService.closeModal.subscribe(val => {
      this.close();
    });
  }

  close() {
    this.title = 'general.errorModal';
    this.elRef.nativeElement.classList.remove('show');
  }

}
