import { Component, OnInit, ElementRef } from '@angular/core';
import { VidBlockContentModule } from './vid-block-content.module';

@Component({
    selector: 'app-vid-block-content',
    templateUrl: './vid-block-content.component.html',
    styleUrls: ['./vid-block-content.component.scss']
})

export class VidBlockContentComponent implements OnInit {
    constructor(
        private elRef: ElementRef,
        private VidBlockContentModule: VidBlockContentModule
    ) {}

    description = '';
    title = 'general.errorModal';

    ngOnInit() {
        this.VidBlockContentModule.openModal.subscribe(val => {
            val.title ? this.title = val.title : '';
            this.description = val.description;
            this.elRef.nativeElement.classList.add('show');
        });

        this.VidBlockContentModule.closeModal.subscribe(val => {
            this.close();
        });
    }

    close() {
        this.title = 'general.errorModal';
        this.elRef.nativeElement.classList.remove('show');
    }

}
