import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox-button',
  templateUrl: './checkbox-button.component.html',
  styleUrls: ['./checkbox-button.component.scss']
})
export class CheckBoxButtonComponent implements OnInit {
  constructor() {}

  @Output() enableChange = new EventEmitter();
  @Input() enable = false;
  @Input() disable = false;

  @Input() error = false;

  enableCheckBox() {
    this.error = false;
    this.enable = !this.enable;
    this.enableChange.emit(this.enable);
  }

  ngOnInit() {
  }
}
