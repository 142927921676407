import { HeaderService } from './header.service';
import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {ApiService} from "../../../core/services/api.service";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  constructor( private headerService: HeaderService,
      private location: Location,
      private router: Router,
      private vidsignerApiService: ApiService
  ) {}

  public showBack = false;
  public showQuestion = false;
  public showCancel = false;
  public showLogo = true;
  public backRoute = false;
  public pdfId = '';

  ngOnInit() {
    this.headerService.statusLogoEvent.subscribe((val) => {
      this.showLogo = val;
    });
    this.headerService.statusBackEvent.subscribe((val) => {
      this.showBack = val;
    });
    this.headerService.statusQuestionEvent.subscribe((val) => {
      this.showQuestion = val;
    });
    this.headerService.statusCancelEvent.subscribe((val) => {
      this.showCancel = val;
    });
    this.headerService.setBackRouteEvent.subscribe((val) => {
      this.backRoute = val;
    });
  }
  goBack() {
    if (this.backRoute) {
      const link = [this.backRoute];
      this.router.navigate(link);
      this.backRoute = false;
    } else {
      this.location.back();
    }
  }

  goBackToDocuments() {
    let link = [`/documents`];
    this.router.navigate(link);
  }

  clickCancel() {
    //redirigir a detalle del pdf si se cancela la firma
    if (this.vidsignerApiService.documents.length > 1 && this.vidsignerApiService.singleDocClicked){
      this.vidsignerApiService.documents.map((document, index) => {
        var idFound = document.SignerDTO.SignerGUI.indexOf(this.vidsignerApiService.documentsSignersArray[0]);
        if (idFound === 0){
          this.pdfId = index;
        }
      });
      let link = [`/documents/${this.pdfId}`];
      this.router.navigate(link);
    }
    //redirigir a selección de documentos si se hace una firma batch
    if (this.vidsignerApiService.documents.length > 1 && !this.vidsignerApiService.singleDocClicked){
      let link = [`/documents`];
      this.router.navigate(link);
      this.headerService.showCancel(false);
    }
    else {
      this.headerService.clickCancelEvent.emit(true);
    }
  }
  goHelp() {
    const link = [`/help`];
    this.router.navigate(link);
  }
}
