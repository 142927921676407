import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { ThemeModule } from '../../theme/theme.module';
@NgModule({
  imports: [
    CommonModule,
    ThemeModule
  ],
  declarations: [LoadingComponent]
})
export class LoadingModule { }
