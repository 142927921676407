import { ApiService } from './../../../core/services/api.service';
import { Component, OnInit, Input } from '@angular/core';
import { checkDisabled, TYPES_COMPONENTS} from '../../helper';

@Component({
  selector: 'app-vid-question',
  templateUrl: './vid-question.component.html',
  styleUrls: ['./vid-question.component.scss']
})
export class VidQuestionComponent implements OnInit {

  constructor(private vidsignerApiService: ApiService) { }
  private TYPES = TYPES_COMPONENTS;
  @Input() data: any = {};

  ngOnInit() {

  }


  disable(component) {
    component.disable = checkDisabled(component, this.vidsignerApiService.formSaved);
    if (component.disable) {
      if (component.type === this.TYPES.RADIOBUTTON) {
        component.selectedchoice = undefined;
      } else {
        component.response = this.clearComponent(component);
      }
    }
    return component.disable;
  }

  checkRequired() {

  }

  clearComponent(comp) {
    if (typeof comp === 'string') {
      comp = '';
    } else {
      comp = false;
    }
  }
}
