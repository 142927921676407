import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-target-info',
  templateUrl: './target-info.component.html',
  styleUrls: ['./target-info.component.scss']
})
export class TargetInfoComponent implements OnInit {
  constructor() {}
  @Input() title;
  @Input() description;
  @Input() num;
  @Input() icon;

  ngOnInit() {}
}
