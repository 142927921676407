import { DocumentErrorComponent } from './pages/document-error/document-error.component';
import { LoadingComponent } from './pages/loading/loading.component';
import { AuthGuardService } from './auth-guard.service';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SSIComponent } from './pages/ssi/ssi.component';

/**
 * Rutas de la aplicación, desde:
 * Root-> '' -> Necesita que sea validado por 'AuthGuardService', que de true para poder acceder.
 * Si entra, las rutas de './pages/pages-routing.module.ts' son las que mueven la aplicación.
 */
const routes: Routes = [
  { path: '', canActivate: [AuthGuardService], loadChildren: './pages/pages.module#PagesModule' },
  { path: 'loading', component: LoadingComponent},
  { path: 'ssi', component: SSIComponent},
  { path: 'document-error', component: DocumentErrorComponent },
  { path: '', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '' }
];

const config: ExtraOptions = {
  useHash: false
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
