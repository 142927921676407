import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vid-question-page',
  templateUrl: './vid-question-page.component.html',
  styleUrls: ['./vid-question-page.component.scss']
})
export class VidQuestionPageComponent implements OnInit {

  constructor() { }

  @Input() data = [];

  ngOnInit() {
  }

}
