import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vid-button',
  templateUrl: './vid-button.component.html',
  styleUrls: ['./vid-button.component.scss']
})
export class VidButtonComponent implements OnInit {
  @Input() icon;
  @Input() disable;
  @Input() loading = false;

  constructor() {}

  ngOnInit() {}


}
