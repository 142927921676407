import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vid-user',
  templateUrl: './vid-user.component.html',
  styleUrls: ['./vid-user.component.scss']
})
export class VidUserComponent implements OnInit {

  constructor() { }
  @Input() name = '';
  ngOnInit() {
  }

}
