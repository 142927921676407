import { NgModule } from '@angular/core';
import { SSIComponent } from './ssi.component';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../theme/theme.module';

@NgModule({
  imports: [
    CommonModule,
    ThemeModule
  ],
  declarations: [SSIComponent]
})
export class SSIModule { }
