import { OnDestroy } from '@angular/core';
import { HeaderService } from '../../theme/components/header/header.service';
import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../core/services/api.service";

@Component({
  selector: 'app-document-error',
  templateUrl: './document-error.component.html',
  styleUrls: ['./document-error.component.scss']
})
export class DocumentErrorComponent implements OnInit, OnDestroy {

  constructor(private headerService: HeaderService,
              private vidsignerService: ApiService)
  { }

  /**
   * Al iniciar el error, que se pueda ver en el header el icono de ayuda.
   */
  ngOnInit() {
    this.vidsignerService.setLangFiles();
    this.headerService.showQuestion(true);
  }

  /**
   * Al salir de la pagina el error, oculta la ayuda.
   */
  ngOnDestroy() {
    this.headerService.showQuestion(false );
  }
}
