import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'app-vid-pdf-viewer',
  templateUrl: './vid-pdf-viewer.component.html',
  styleUrls: ['./vid-pdf-viewer.component.scss']
})
export class VidPdfViewerComponent implements OnInit {

  @Input() images = [];
  @Input() mobile = false;
  @Input() pagePdf = 1;
  @Output() pdfLoad = new EventEmitter();
  @Output() pdfPageChange = new EventEmitter();
  maxNumPdfPages: number = this.images.length;
  imagesLoaded = 0;
  pfdviewerElm: any = '';
  constructor(private elem: ElementRef) {

  }

  ngOnInit() {
    this.loadEvents();
    this.elem.nativeElement.onscroll = this.checkPage.bind(this);
  }

  onLoadImages() {
    this.imagesLoaded++;
    if (this.imagesLoaded === this.images.length) {
      this.pdfLoad.emit(this.images);
    }
  }

  loadEvents() {
    const options: any = {
      preventDefault: true,
      domEvents: true
    };

  }
  checkPage() {
    let aux = this.pagePdf;
    this.images.map( (val, i) => {
      const element = document.getElementById(`doc-pag-${i}`);
      if (this.elementInViewport(element)) {
        if (aux !== i + 1 ) {
          aux = i + 1;
        }
      }
    });
    if (aux !== this.pagePdf) {
      this.pagePdf = aux;
      this.pdfPageChange.emit(this.pagePdf);
    }
  }

  elementInViewport(el) {
    const bounding = el.getBoundingClientRect();
    const boundingElm = this.elem.nativeElement.getBoundingClientRect();
    return bounding.top - boundingElm.top <= 0 && bounding.top + bounding.height > 0;
  }

}
