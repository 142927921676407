import { Router, ActivatedRoute, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiService } from './core/services/api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private vidsignerService: ApiService, private router: Router, ) {
  }

  /**
   * Función que se llama siempre en el Loading y que antes de ir hacia otra Sub ruta debe de dar bien.
   * Si no esta bien, La pagina se queda o va al Loading
   * Si va bien, Te deja en la pagina actual que estes y te deja acceso.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return new Promise((resolve, reject) => {
      this.vidsignerService.isAuthenticated().subscribe(val => {
        if (!val) {
          this.router.navigate(['/loading']);
          resolve(false);
        } else {
          resolve(true);
        }
      }); // canActive can return Observable<boolean>, which is exactly what isAuhenticated returns
    });
  }
}
