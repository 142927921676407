import { ThemeModule } from '../../theme/theme.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentErrorComponent } from './document-error.component';

@NgModule({
  imports: [
    CommonModule,
    ThemeModule
  ],
  declarations: [DocumentErrorComponent]
})
export class DocumentErrorModule { }
