import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vid-card',
  templateUrl: './vid-card.component.html',
  styleUrls: ['./vid-card.component.scss']
})
export class VidCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
