import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vid-question-checkbox',
  templateUrl: './vid-question-checkbox.component.html',
  styleUrls: ['./vid-question-checkbox.component.scss']
})
export class VidQuestionCheckboxComponent implements OnInit {

  @Input() data: any;
  @Input() disable = false;
  @Input() error = false;
  constructor() {
    this.disable = false;
    this.error = false;
  }

  ngOnInit() {
  }


  checkResponse(real, value) {
    this.data.response = value;
    this.error = false;
    if (real !== value) {
      this.data.response = undefined;
    }
  }

}
