import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutofocusDirective } from './directive/autofocus.directive';

import {
  HeaderComponent,
  TargetInfoComponent,
  VidButtonComponent,
  TargetPdfComponent,
  RadioButtonComponent,
  CheckBoxButtonComponent,
  VidModalComponent,
  VidModalErrorComponent,
  VidModalWarningComponent,
  VidBlockContentComponent,
  VidUserComponent,
  VidPdfViewerComponent,
  VidBottomCopyrightComponent,
  VidQuestionComponent,
  VidQuestionPageComponent,
  VidQuestionCheckboxComponent,
  VidQuestionRadiobuttonComponent,
  VidQuestionTextboxComponent,
  VidCardComponent,
} from './components';



const COMPONENTS = [
  HeaderComponent,
  TargetInfoComponent,
  VidButtonComponent,
  TargetPdfComponent,
  RadioButtonComponent,
  CheckBoxButtonComponent,
  VidModalComponent,
  VidUserComponent,
  VidPdfViewerComponent,
  VidModalErrorComponent,
  VidModalWarningComponent,
  VidBlockContentComponent,
  VidBottomCopyrightComponent,
  VidQuestionComponent,
  VidQuestionPageComponent,
  VidQuestionCheckboxComponent,
  VidQuestionRadiobuttonComponent,
  VidQuestionTextboxComponent,
  VidCardComponent,
  AutofocusDirective
];
const BASE_COMPONENTS = [CommonModule, FormsModule, ReactiveFormsModule];

@NgModule({
  imports: [...BASE_COMPONENTS, TranslateModule],
  exports: [...COMPONENTS, TranslateModule],
  declarations: [...COMPONENTS]
})
export class ThemeModule {}
