import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ApiService} from "../../../core/services/api.service";

@Component({
  selector: 'app-target-pdf',
  templateUrl: './target-pdf.component.html',
  styleUrls: ['./target-pdf.component.scss']
})
export class TargetPdfComponent implements OnInit {

  @Input() public docId: string;
  @Input() public signerId: string;
  @Input() public title: string;
  @Input() public fileName: string;
  @Input() public image: string;
  @Input() public docSnapshot: string;
  @Input() public signed: boolean;
  @Input() public rejected: boolean;
  @Input() public documentsChecked: boolean;
  @Input() public documentsId : string[];
  @Input() public signersId: string[];
  @Input() public documents : string[];
  @Input() public disableSignAll : boolean;
  @Input() public allowBatchMultisignature : boolean;
  @Output() onClickSignButton = new EventEmitter();
  @Output() disableSignAllEmitter = new EventEmitter();

  public singleDocClicked: boolean = false;
  public disable: boolean = true;


  constructor(
      private vidsignerApiService: ApiService
  ) {}

  ngOnInit() {
  }

  handleClickSign() {
    this.onClickSignButton.emit(true);
    this.singleDocClicked = true;
    this.vidsignerApiService.singleDocClicked = this.singleDocClicked;
    var index = this.documentsId.indexOf(this.docId);
    /**el valor no existe y por lo tanto se hace push en el array*/
    if (index === -1) {
      this.documentsId.push(this.docId);
      this.signersId.push(this.signerId);
    }
    this.vidsignerApiService.documentsSignersArray = this.signersId;
  }

  handleCheckDoc(event) {
    if (event) {
      var index = this.documentsId.indexOf(this.docId);
      /**el valor no existe y por lo tanto se hace push en el array*/
      if (index === -1) {
        this.documentsId.push(this.docId);
        this.signersId.push(this.signerId);
        this.documentsChecked = true;
      }
    }
    else{
      var index = this.documentsId.indexOf(this.docId);
      if (index > -1) {
        this.documentsId.splice(index, 1);
        this.signersId.splice(index, 1);
        this.documentsChecked = false;
      }
    }
    this.documentsId.length === 0 ? this.disable = true : this.disable = false;
    this.disableSignAllEmitter.emit(this.disable);

    this.vidsignerApiService.documentsSignersArray = this.signersId;
  }

}
