import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {
  constructor() {}

  @Output() enableChange = new EventEmitter();
  @Input() enable = false;
  @Input() disable = false;
  @Input() error = false;
  toggleEnable() {
    this.enable = !this.enable;
    this.enableChange.emit(this.enable);
  }
  ngOnInit() {
  }
}
