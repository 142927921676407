import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vid-bottom-copyright',
  templateUrl: './vid-bottom-copyright.component.html',
  styleUrls: ['./vid-bottom-copyright.component.scss']
})
export class VidBottomCopyrightComponent implements OnInit {

  param = {
    year : new Date().getFullYear()
  };

  constructor() { }

  ngOnInit() {
  }

}
