import { ApiService } from '../api.service';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class SSIService {

	constructor(
		private vidsignerApiService: ApiService,
		private http: HttpClient
	) { }

	/**
     * Generate an URL for SSIID redirection
     */
    generateSSIUrl(signerGUI: string): Observable<any> {
        const url = `${this.vidsignerApiService.vidUrlApiPriv}emailsignatures/${signerGUI}/ssiurl`;
        const httpOptions = {
            headers: this.vidsignerApiService.generateOAuthRequestHeaders()
        };
		
		return this.http.get(url, httpOptions);
	}

	/**
     * Send SSI Parameters
     *
     * @param state has (emailid, signatureid)
     * @param code
     */
    sendSSICode(state: string, code: string): Observable<any> {
        return Observable.create((observer: Observer<any>) => {
			this.http.get(this.vidsignerApiService.ipUrl).toPromise().then((data: any) => {

				var emailId = atob(state).split(",")[0];

				const url = `${this.vidsignerApiService.vidUrlApiPriv}emailsignatures/${emailId}/ssicode`;

				const httpOptions = {
					headers: this.vidsignerApiService.generateOAuthRequestHeaders()
				};

				const requestDTO = {
					code: code,
					IpAddress: data.ip,
					Location: this.vidsignerApiService.position,
					From: this.vidsignerApiService.browser
				};

				this.http.post(url, requestDTO, httpOptions).toPromise()
					.then((data: any) => {
						observer.next(data);
						observer.complete();
					}).catch(error => {
					observer.error(error);
				});
                
            }).catch(error => {
                observer.error(error);
            });
        });
    }
}
