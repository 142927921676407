import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-vid-modal',
  templateUrl: './vid-modal.component.html',
  styleUrls: ['./vid-modal.component.scss']
})
export class VidModalComponent implements OnInit {
  constructor(private elRef: ElementRef) {}

  ngOnInit() {}

}
