import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Observer} from 'rxjs';
import {filter, map, take, toArray, timeout, catchError} from 'rxjs/operators';
import location from 'browser-location';
import gzip from 'gzip-js';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    // AzureLab Urls
    vidUrlApiMng = 'https://labs-vidsignercloudmgmt.validatedid.com/api/';
    vidUrlApiPriv = 'https://labs-fdgoihnerw.validatedid.com/api/';
    vidUrlApiPub = 'https://labs-vidsignercloud.validatedid.com/api/v2.1/';
    username = 'vidremotepre';
    password = 'pvr16phb23r10ro46avh77ole';

    // PRE Urls
    // vidUrlApiMng = 'https://pre-vidsignercloudmgmt.validatedid.com/api';
    // vidUrlApiPriv = 'https://pre-fdgoihnerw.validatedid.com/api';
    // vidUrlApiPub = 'https://pre-vidsignercloud.validatedid.com/api';
    // username = 'vidremotepre';
    // password = 'pvr16phb23r10ro46avh77ole';

    // PRO Urls
    //vidUrlApiMng = 'https://vidsignercloudmgmt.validatedid.com/api';
    //vidUrlApiPriv = 'https://fdgoihnerw.validatedid.com/api';
    //vidUrlApiPub = 'https://vidsignercloud.validatedid.com/api';
    //username = 'vidremote';
    //password = 'u86uf0ls89re17dyjhnhgsumw';

    /** IP Url*/
    //ipUrl = 'ip.php';
    /**Test to local*/
    ipUrl = '/assets/ip.json';

    emailId = '';
    position = '';
    clientIP: any = '';
    documents: any = [];
    vidSignerDocument: any = {};
    otp = '';
    documentsSignersArray = [];
    singleDocClicked: boolean = false;
    otpSended = false;
    skipSMS = false;
    isBankId = false;
    isAutofirma = '';
    isSwisscom = false;
    skipSigDraw = false;
    formSaved = null;
    oAuthToken: string = null;
    frontalbase64: string = null;
    backbase64: string = null;
    browser: any = {};
    redirectUrl = null;
    IdDocumentSent = false;
    allowBatchMultiSignature: boolean = false;
    phoneNumber = null;
    urlReturn: string = null;
    allowDownloadDocument = true;
    nextDocumentURL = null;
    isSSI = false;

    constructor(
        private http: HttpClient,
        private router: Router,
        private translate: TranslateService
    ) {
        this.restartParameters();
    }

    /**
     * Init again all parameters.
     */
    restartParameters() {
        this.emailId = '';
        this.position = '';
        this.documents = [];
        this.vidSignerDocument = {};
        this.otp = '';
        this.otpSended = false;
        this.skipSMS = false;
        this.isBankId = false;
        this.isAutofirma = '';
        this.isSwisscom = false;
        this.skipSigDraw = false;
        this.phoneNumber = null;
        this.isSSI = false;
    }

    /**
     * Comprueba con que idioma vienen los documentos y configura el lenguaje a mostrar.
     * Si no hay documento disponible, recoge el idioma del navegador
     */

    setLangFiles() {
        if (this.documents.length > 0) {
            const langs = this.documents.map(val => val.SignerDTO.Language);
            this.translate.use(langs[0]); // ahora el primero ya que solo hay un documento.
        }
        else if (this.documents.length === 0) {
            const userLang = navigator.language || navigator['userLanguage'];
            var lang = userLang.split("-");
            if (lang[0] !== "ca" && lang[0] !== "de" && lang[0] !== "en" && lang[0] !== "es" && lang[0] !== "fr" && lang[0] !== "nl" && lang[0] !== "pt"){
                this.translate.use("en");
            }
            else {
                this.translate.use(lang[0]);
            }
        }
    }

    /**
     * Check if is authenticated
     *
     */
    isAuthenticated() {
        const observable = new Observable((observer) => {
            observer.next(this.oAuthToken !== null);
            observer.complete();
        });
        return observable;
    }

    /**
     * Generate oAuth Request Headers
     *
     * @param oAuthToken
     */
    generateOAuthRequestHeaders() {
        return new HttpHeaders({
            'Authorization': `Bearer ${this.oAuthToken}`,
            'Content-Type': 'application/json;charset=utf-8',
            'Accept': 'application/json;charset=utf-8',
        });
    }

    /**
     * Generate Auth Basic Request Headers
     */
    generateAuthBasicRequestHeaders() {
        return new HttpHeaders({
            'Authorization': 'Basic ' + btoa(`${this.username}:${this.password}`),
            'Content-Type': 'application/json;charset=utf-8',
            'Accept': 'application/json;charset=utf-8'
        });
    }

    /**
     * Update Location
     */

    updateLocation() {
        location.get(function (err, pos: any) {
            if (pos) {
                this.position = pos.coords.latitude + ',' + pos.coords.longitude;
            }
        }.bind(this));
    }

    /**
     * Get oAuth Token by email id
     *
     * @param emailId
     */
    getOauthEmailToken(emailId: string): Observable<any> {

        const url = `${this.vidUrlApiMng}/oauth/token`;

        const httpOptions = {
            headers: this.generateAuthBasicRequestHeaders()
        };

        const requestDTO = {
            username: emailId,
            password: '',
            grant_type: 'password',
            scope: 'email'
        };

        const responseObs = new Observable((observer) => {
            this.http.post(url, requestDTO, httpOptions)
                .subscribe((response: any) => {
                        if (response.status === 401) {
                            observer.error(response.message);
                        }
                        this.emailId = emailId;
                        this.oAuthToken = response.access_token;
                        observer.next(response.access_token);
                        observer.complete();
                    },
                    error => {
                        observer.error(error.message);
                    });
        });
        return responseObs;
    }

    /**
     * Get Unsigned document (or documents) by email id
     *
     * @param emailId
     * @param oAuthToken
     */
    getUnsignedDocumentByEmailId(emailId: string, /*oAuthToken: string*/): Observable<any> {

        const url = `${this.vidUrlApiPriv}/pendingsignatures/searches`;

        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };

        const requestDTO = {
            Terms: {
                EmailId: this.emailId
            }
        };

        const responseObs = new Observable((observer) => {
            this.http.post(url, requestDTO, httpOptions)
                .subscribe(documents => {
                    this.documents = documents;
                    if (this.documents.length > 0) {
                        this.allowBatchMultiSignature = this.documents[0].SignerDTO.AllowBatchMultiSignature;
                        this.skipSMS = this.documents[0].SignerDTO.SkipSMS;
                        this.isBankId = this.documents[0].SignerDTO.isBankId;
                        this.isAutofirma = this.documents[0].SignerDTO.LocalSignature;
                        this.skipSigDraw = this.documents[0].SignerDTO.SkipSigDraw;
                        this.phoneNumber = this.documents[0].SignerDTO.PhoneNumber;
                        this.allowDownloadDocument = this.documents[0].SignerDTO.AllowDownloadDocument;
                        this.isSwisscom = this.documents[0].SignerDTO.AuthWithSwisscom;
                        this.isSSI = this.documents[0].SignerDTO.AuthWithSSI;
                    }
                    // update GPRD
                    observer.next(documents);
                    observer.complete();
                    if (this.documents.length > 1 && !this.documents[0].SignerDTO.AllowBatchMultiSignature) {
                        this.loadDocumentThumbnails().subscribe(val => {
                            observer.next(documents);
                            observer.complete();
                        });
                    }
                    /*this.loadDetailDocuments().subscribe(val => {
                      observer.next(documents);
                      observer.complete();
                    });*/

                }, e => {
                    this.router.navigate(['/document-error']);
                });
        });

        return responseObs;
    }

    /**
     * Send BankId Parameters
     *
     * @param state
     * @param code
     */
    sendBankIdCode(state: string, code: string): Observable<any> {
        return Observable.create((observer: Observer<any>) => {
            var stateb64 = atob(state);
            var emailId = stateb64.split(",");
            const url = `${this.vidUrlApiPriv}/emailsignatures/${emailId[0]}/bankidcode`; // el signature_guid viene por el state que se recoge por param (emailid, signatureid)
            const httpOptions = {
                headers: this.generateOAuthRequestHeaders()
            };
            const requestDTO = {
                code: code
            };
            this.http.post(url, requestDTO, httpOptions).toPromise()
                .then((data: any) => {
                    observer.next(data);
                    observer.complete();
                }).catch(error => {
                observer.error(error);
            });
        });
    }

    /**
     * Download Document
     *
     * @param docGUID
     */
    downloadDocument(docGUID: string): Observable<any> {
        const url = `${this.vidUrlApiPriv}/documents/${docGUID}`;

        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };

        return this.http.get(url, httpOptions);
    }

    /**
     * Get rendered document by doc GUID
     *
     * @param docGUID
     * @param oAuthToken
     */
    getDocumentRendered(docGUID: string): Observable<any> {

        const url = `${this.vidUrlApiPriv}/documentrendered/${docGUID}`;

        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };

        return this.http.get(url, httpOptions);
    }

    getThumbnailsRendered(docGUID: string): Observable<any> {

        const url = `${this.vidUrlApiPriv}/documentsnapshot/${docGUID}`;

        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };

        return this.http.get(url, httpOptions);
    }

    /**
     * update documents including GPRD protection
     *
     * @param docGUID
     * @param oAuthToken
     */
    loadDetailDocuments(): any {

        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };

        return Observable.create((observer: Observer<any>) => {
            const promises = [];
            this.documents.map((val, i) => {
                const url = `${this.vidUrlApiPriv}/pendingsignatures/${val.SignerDTO.SignerGUI}/rgpdtext/${val.SignerDTO.Language}`;
                const promiseRGPD = this.http.get(url, httpOptions).toPromise().then(result => {
                    this.documents[i].SignerDTO.GPRD = result;
                }).catch(error => {
                });
                const promiseIMG = this.getDocumentRendered(val.DocGUI).toPromise().then(data => {
                    this.documents[i].SignerDTO.docPageRendered = data.DocPageRendered;
                });
                promises.push(promiseRGPD);
                promises.push(promiseIMG);
            });
            Promise.all(promises).then(val => {
                observer.next(val);
                observer.complete();
            });
        });
    }

    compress(file: File): Observable<any> {
        const width = 200; // For scaling relative to width
        const reader = new FileReader();
        reader.readAsDataURL(file);
        return Observable.create(observer => {
            reader.onload = ev => {
                const img = new Image();
                img.src = (ev.target as any).result;
                (img.onload = () => {
                    const elem = document.createElement('canvas'); // Use Angular's Renderer2 method
                    const scaleFactor = width / img.width;
                    elem.width = width;
                    elem.height = img.height * scaleFactor;
                    const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
                    ctx.canvas.toBlob(
                        blob => {
                            observer.next(
                                new File([blob], file.name, {
                                    type: 'image/jpeg',
                                    lastModified: Date.now(),
                                }),
                            );
                        },
                        'image/jpeg',
                        1,
                    );
                });
                    (reader.onerror = error => observer.error(error));
            };
        });
    }

    loadDocumentThumbnails(): any {
        return Observable.create((observer: Observer<any>) => {
            const promises = [];
            this.documents.map((val, i) => {
                const promiseThumbnails = this.getThumbnailsRendered(val.DocGUI).toPromise().then(data => {
                    this.documents[i].SignerDTO.DocSnapshot = data.DocSnapshot;
                });
                promises.push(promiseThumbnails);
            });
            Promise.all(promises).then(val => {
                observer.next(val);
                observer.complete();
            });
        });
    }

    /**
     * Sends signature evidence
     *
     * @param evidenceType
     */
    postSignatureEvidence(evidenceType: string): Observable<any> {
        return Observable.create((observer: Observer<any>) => {
            this.http.get(this.ipUrl).toPromise().then((data: any) => {
                this.clientIP = data.ip;

                const url = `${this.vidUrlApiPriv}/emailsignatures/${this.emailId}/evidence`;

                const httpOptions = {
                    headers: this.generateOAuthRequestHeaders()
                };

                const requestDTO = {
                    EvidenceType: evidenceType,
                    EvidenceIP: this.clientIP,
                    EvidenceLocation: this.position,
                    From: this.browser
                };

                this.http.post(url, requestDTO, httpOptions).subscribe(result => {
                    observer.next(result);
                    observer.complete();
                }, e => {
                    observer.error(e);
                });
            }).catch(error => {
                observer.error(error);
            });
        });
    }

    openTab(url: string) {
        // Create link in memory
        var a = window.document.createElement("a");
        a.target = '_blank';
        a.href = url;

        // Dispatch fake click
        var e = window.document.createEvent("MouseEvents");
        e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);
    };

    /**
     * Sends IdDocument evidence
     *
     * @param evidenceType
     */
    postIdDocumentEvidence(evidenceType: string): Observable<any> {
        return Observable.create((observer: Observer<any>) => {
            this.http.get(this.ipUrl).toPromise().then((data: any) => {
                const clientIp = data.ip;
                const clientLocation = this.position;
                const from = this.browser;
                const frontalbase64 = this.frontalbase64;
                const backbase64 = this.backbase64;

                const url = `${this.vidUrlApiPriv}/emailsignatures/${this.emailId}/evidence`;

                const httpOptions = {
                    headers: this.generateOAuthRequestHeaders()
                };

                const requestDTO = {
                    EvidenceType: evidenceType,
                    EvidenceIP: clientIp,
                    EvidenceLocation: clientLocation,
                    From: from,
                    IdDocument: {
                        FrontImage: frontalbase64,
                        BackImage: backbase64,
                    }
                };

                this.http.post(url, requestDTO, httpOptions).subscribe(result => {
                    observer.next(result);
                    observer.complete();
                }, e => {
                    observer.error(e);
                });
            }).catch(error => {
                observer.error(error);
            });
        });
    }

    /**
     * Post email signature
     *
     * @param signerGUI
     * @param oAuthToken
     * @param signatureImage Signature image data on Base64
     * @param signatureRawData Array of signature points
     */
    postEmailSignature(
        signerGUI: string,
        signatureImage: string,
        signatureRawData: any
    ): Observable<any> {
        return Observable.create((observer: Observer<any>) => {
            this.http.get(this.ipUrl).toPromise().then((data: any) => {
                const clientIp = data.ip;
                const clientLocation = this.position;
                const from = this.browser;
                var url = `${this.vidUrlApiPriv}/emailsignatures/${signerGUI}`;
                if (!this.singleDocClicked && this.documents.length > 1) {
                    url = `${this.vidUrlApiPriv}/emailsignatures`;
                    /**Para pruebas con la máquina virtual */
                    //url = `http://192.168.0.41:8080/api/emailsignatures`;
                }

                let signatureRawDataZippedAndEncoded = '';
                if (signatureRawData) {
                    const timestampStart = signatureRawData[0][0].time;

                    const signatureRawDataStr = signatureRawData.reduce((str, element) => {
                        const stroke = element.reduce((str, rd, i) => {
                            const time = rd.time - timestampStart;
                            const {x, y, pressure} = rd;
                            const letter = i === 0 ? 'U' : 'T';
                            return `${str}${time}:${x}:${y}:${pressure}:${letter};`;
                        }, '');
                        return `${str}${stroke}`;
                    }, '');

                    signatureRawDataZippedAndEncoded = this.base64ArrayBuffer(gzip.zip(signatureRawDataStr));
                }

                /*let documentsSigners = this.documentsSignersArray.map(function(document) {
                  return document.SignerDTO.SignerGUI;
                });*/

                const httpOptions = {
                    headers: this.generateOAuthRequestHeaders()
                };

                var requestDTO = {};
                if (!this.singleDocClicked && this.documents.length > 1) {
                    requestDTO = {
                        SignatureGUIDs: this.documentsSignersArray,
                        EmailSignature: {
                            SignatureImage: signatureImage.substr(22),
                            SignatureRawData: signatureRawDataZippedAndEncoded,
                            OTP: this.otp,
                            IpAddress: clientIp,
                            Location: clientLocation,
                            From: from
                        }
                    };
                } else {
                    requestDTO = {
                        SignatureImage: signatureImage.substr(22),
                        SignatureRawData: signatureRawDataZippedAndEncoded,
                        OTP: this.otp,
                        IpAddress: clientIp,
                        Location: clientLocation,
                        From: from
                    };
                }
                /**Si es firma batch, hay más de 15 docs y no hay parámetro de redirect por url, no se espera al envío y se redirecciona al final */
                if (this.documents[0].SignerDTO.AllowBatchMultiSignature && this.documents.length > 15 && typeof this.redirectUrl === 'undefined') {
                    let link = ['/process/finish'];
                    this.router.navigate(link);
                }
                /**Si es una firma batch y han pasado por url en b64 una redirección, se actualiza la window */
                if (this.documents[0].SignerDTO.AllowBatchMultiSignature && this.documents.length > 15 && typeof this.redirectUrl !== 'undefined') {
                    window.top.location.href = atob(this.redirectUrl);
                }
                this.http.post(url, requestDTO, httpOptions).subscribe(result => {
                    // look for documents that are signed and remove them from documents array list
                    var docIsSigned;
                    for (var i = this.documents.length - 1; i >= 0; i--) {
                        docIsSigned = this.documentsSignersArray.includes(this.documents[i].SignerDTO.SignerGUI);
                        if (docIsSigned) {
                            this.documents.splice(i, 1);
                        }
                    }
                    observer.next(result);
                    observer.complete();
                }, err => {
                    observer.error(err);
                });

            }).catch(error => {
                observer.error(error);
            });
        });

    }

    /**
     * Get next document
     */
    getNextDocument(): Observable<any> {
        const url = `${this.vidUrlApiPriv}/emailsignatures/${this.emailId}/nextdocument`;
        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };

        return this.http.get(url, httpOptions);
    }

    /**
     * Reject email signature
     * @param signerGUI
     * @param reason
     */
    postRejectedEmailSignature(
        signerGUI: string,
        reason: string,
    ): Observable<any> {
        const url = `${this.vidUrlApiPriv}/rejectedsignature/${signerGUI}`;

        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };

        const requestDTO = {
            RejectionReason: reason
        };

        return this.http.post(url, requestDTO, httpOptions);
    }

    /**
     * Generate an URL for BankId redirection
     */
    generateBankIdUrl(signerGUI: string): Observable<any> {
        const url = `${this.vidUrlApiPriv}/emailsignatures/${signerGUI}/bankidurl`;
        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };
        return this.http.get(url, httpOptions);
    }

    /**
     * Get RequestId and RedirectUrl for Swisscom
     * @param signerGUI 
     */ 
    swisscomSignRequest(signerGUI: string, phoneNumber: string): Observable<any> {
        const url = `${this.vidUrlApiPriv}/emailsignatures/swisscom/${signerGUI}/signrequest`;
        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };

        const requestDTO = {
            IpAddress: this.clientIP,
            Location: this.position,
            From: this.browser,
            PhoneNumber: phoneNumber
        };

        return this.http.post(url, requestDTO, httpOptions);
    }

    /**
     * Complete Swisscom signature
     * @param signatureGUI 
     * @param requestID 
     * @param phoneNumber 
     */
    completeSwisscomSignature(signatureGUI: string, requestID: string, phoneNumber: string): Observable<any> {
        const url = `${this.vidUrlApiPriv}/emailsignatures/swisscom/${signatureGUI}/completesignature/${requestID}`;

        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };

        const requestDTO = {
            IpAddress: this.clientIP,
            Location: this.position,
            From: this.browser,
            PhoneNumber: phoneNumber
        };

        return this.http.post(url, requestDTO, httpOptions);
    }
    
    /**
     * Request an OTP for Email Signature
     */
    putEmailSignatureOTP(phoneNumber: string = null): Observable<any> {
        const url = `${this.vidUrlApiPriv}/emailsignatures/${this.emailId}/otpsms`;

        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };

        var params = !phoneNumber ? {PhoneNumber: this.phoneNumber} : {PhoneNumber: phoneNumber};
        
        return this.http.put(url, params, httpOptions);
    }

    /**
     * Check an OTP for Email Signature
     */
    putEmailSignatureCheckOTP(): Observable<any> {
        const url = `${this.vidUrlApiPriv}/emailsignatures/${this.emailId}/otpcode`;

        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };

        const checkOtpDTO = {
            OTP: this.otp
        };

        return this.http.put(url, checkOtpDTO, httpOptions);
    }

    /**
     * Send Form response
     *
     * @param {string} signerGUI
     * @param form FormDTO
     * @param formResponse Response from Form. {id: any, response: any}
     * @returns {Observable<any>}
     */
    putSignatureFormResponse(
        signerGUI: string,
        form: any,
        formResponse: any
    ): Observable<any> {
        const url = `${this.vidUrlApiPriv}/signatures/${signerGUI}/formresponse`;

        const formResponseDTO = this.bindResponsesToForm(form, formResponse);

        const httpOptions = {
            headers: this.generateOAuthRequestHeaders()
        };

        return this.http.put(url, formResponseDTO, httpOptions);
    }

    /**
     * Bind responses to form object
     * @param form
     * @param formResponse
     * @returns {any}
     */
    bindResponsesToForm(
        form: any,
        formResponse: any
    ): any {
        const sections = form.Sections.map((section) => {
            section.Groups.map((group) => {
                group.RadioButtons === null ? group.RadioButtons = null : '';
                group.CheckBoxes === null ? group.CheckBoxes = null : '';
                group.TextBoxes === null ? group.TextBoxes = null : '';
                if (('RadioButtons' in group) && group.RadioButtons !== null && group.RadioButtons.length > 0) {
                    group.RadioButtons.map((t) => {
                        const response = formResponse.filter((_response) => {
                            return _response.id === t.Id;
                        });
                        if (response.length > 0) {
                            t.SelectedChoice = +(response[0].response) + 1;
                        }

                        return t;
                    });
                }

                if (('CheckBoxes' in group) && group.CheckBoxes !== null && group.CheckBoxes.length > 0) {
                    group.CheckBoxes.map((t) => {
                        const response = formResponse.filter((_response) => {
                            return _response.id === t.Id;
                        });
                        if (response.length > 0) {
                            t.Response = response[0].response;
                        }

                        return t;
                    });
                }

                if (('TextBoxes' in group) && group.TextBoxes !== null && group.TextBoxes.length > 0) {
                    group.TextBoxes.map((t) => {
                        const response = formResponse.filter((_response) => {
                            return _response.id === t.Id;
                        });
                        if (response.length > 0) {
                            t.Response.Text = response[0].response;
                        }

                        return t;
                    });
                }

                return group;
            });

            return section;
        });

        form.Sections = sections;
        return form;
    }

    /**
     * Encode array buffer to base64 string
     *
     * @param arrayBuffer
     */
    base64ArrayBuffer(arrayBuffer): string {
        let base64 = '';
        const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

        const bytes = new Uint8Array(arrayBuffer);
        const byteLength = bytes.byteLength;
        const byteRemainder = byteLength % 3;
        const mainLength = byteLength - byteRemainder;

        let a;
        let b;
        let c;
        let d;
        let chunk;

        // Main loop deals with bytes in chunks of 3
        for (let i = 0; i < mainLength; i += 3) {
            // Combine the three bytes into a single integer
            chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

            // Use bitmasks to extract 6-bit segments from the triplet
            a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
            b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
            c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
            d = chunk & 63;        // 63       = 2^6 - 1

            // Convert the raw binary segments to the appropriate ASCII encoding
            base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
        }

        // Deal with the remaining bytes and padding
        if (byteRemainder === 1) {
            chunk = bytes[mainLength];

            a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

            // Set the 4 least significant bits to zero
            b = (chunk & 3) << 4; // 3   = 2^2 - 1

            base64 += `${encodings[a]}${encodings[b]}==`;
        } else if (byteRemainder === 2) {
            chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

            a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
            b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

            // Set the 2 least significant bits to zero
            c = (chunk & 15) << 2; // 15    = 2^4 - 1

            base64 += `${encodings[a]}${encodings[b]}${encodings[c]}=`;
        }

        return base64;
    }

    /**
     * This will work with any version of Safari across all devices: Mac, iPhone, iPod, iPad.
     */
    isSafari(){
        return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') == -1 &&
               navigator.userAgent.indexOf('FxiOS') == -1;
    }
}
