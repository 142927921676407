import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { SSIService } from 'src/app/core/services/ssi/ssi.service';

@Component({
  selector: 'app-ssi',
  templateUrl: './ssi.component.html',
  styleUrls: ['./ssi.component.scss']
})
export class SSIComponent implements OnInit {
  percentage = 0;
  error = '';
  loading = false;
  
  constructor(private activatedRoute: ActivatedRoute,
              private vidsignerService: ApiService,
              private ssiService :SSIService,
              private router: Router) 
  {
    // Try to get the app location
    this.vidsignerService.updateLocation();
  }

  ngOnInit() {
    this.vidsignerService.setLangFiles();
    
    this.activatedRoute.queryParams.subscribe(params => {
      var state = params['state'];
      var code = params['code'];

      this.loading = true;
      this.percentage = 20;

      if (typeof state !== "undefined" && typeof code !== "undefined"){
        var stateb64 = atob(state);
        var emailId = stateb64.split(",")[0];
        this.percentage = 40;
        
        this.vidsignerService.getOauthEmailToken(emailId).subscribe( response => {
          this.percentage = 60;
              this.ssiService.sendSSICode(state, code).subscribe(val => {
                this.vidsignerService.getUnsignedDocumentByEmailId('').subscribe(documents => {
                  this.percentage = 80;
                  
                  if (documents.length > 0) {
                    var lastDoc = documents[0]
                    if (this.vidsignerService.documents.length === 1) {
                      this.vidsignerService.documentsSignersArray.push(lastDoc.SignerDTO.SignerGUI);
                    }
                    this.vidsignerService.vidSignerDocument = lastDoc;
                    
                    // Send email clicked evidence
                    this.vidsignerService.postSignatureEvidence('EMAIL_CLICKED').subscribe(val => {
                      var link = [`/documents/0/sign`];
                      /**Redirección a selección de imágenes de DNI si IdDocument es true en el documento y además es SSI*/
                      if (this.vidsignerService.documents[0].SignerDTO.AttachIdDocImages === true){
                        link = [`/documents/0/IdDocument`];
                      }
                      this.percentage = 100;
                      
                      setTimeout(() => {
                        this.loading = false;
                        this.router.navigate(link);
                      }, 2000);
                    }, e => {
                      this.router.navigate(['/document-error']);
                    });
                  } else {
                    this.router.navigate(['/document-error']);
                  }
                });

              }, e => {
                console.log('sendSSICode error:' + e)
                this.router.navigate(['/document-error']);
              });
            },
            e => {
              console.log('Invalid URL -> getOauthEmailToken: ' + e);
              this.router.navigate(['/document-error']);
            });
          }
    });
  }
}

