import { Injectable, EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor() { }

  @Output() statusLogoEvent = new EventEmitter();
  @Output() statusBackEvent = new EventEmitter();
  @Output() statusCancelEvent = new EventEmitter();
  @Output() statusQuestionEvent = new EventEmitter();
  @Output() clickCancelEvent = new EventEmitter();
  @Output() setBackRouteEvent = new EventEmitter();

  public showLogo(status) {
    this.statusLogoEvent.emit(status);
  }

  public showBack(status) {
    this.statusBackEvent.emit(status);
  }

  public showQuestion(status) {
    this.statusQuestionEvent.emit(status);
  }

  public showCancel(status) {
    this.statusCancelEvent.emit(status);
  }

  public setBackRoute(back) {
    this.setBackRouteEvent.emit(back);
  }

  public hideAllButtons() {
    this.showLogo(false);
    this.showBack(false);
    this.showQuestion(false);
    this.showCancel(false);
  }

  public hideAllButtonsWithoutLogo() {
    this.showLogo(true);
    this.showBack(false);
    this.showQuestion(false);
    this.showCancel(false);
  }

  public showAllButtons() {
    this.showLogo(true);
    this.showBack(true);
    this.showCancel(true);
  }

}
