import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-vid-question-textbox',
  templateUrl: './vid-question-textbox.component.html',
  styleUrls: ['./vid-question-textbox.component.scss']
})
export class VidQuestionTextboxComponent implements OnInit {

  @Input() data: any;
  @Input() disable = false;
  @Input() error = false;
  constructor() {
    this.disable = false;
    this.error = false;
  }

  ngOnInit() {
  }

  onchange() {
    this.error = false;
  }
}
