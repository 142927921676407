import { Component, OnInit, ElementRef } from '@angular/core';
import { VidModalWarningService } from './vid-modal-warning.service';
import {HeaderService} from "../header/header.service";
import {ApiService} from "../../../core/services/api.service";
import {VidModalErrorService} from "../vid-modal-error/vid-modal-error.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";

@Component({
  selector: 'app-vid-modal-warning',
  templateUrl: './vid-modal-warning.component.html',
  styleUrls: ['./vid-modal-warning.component.scss']
})
export class VidModalWarningComponent implements OnInit {

  public sending: boolean = false;
  public documents: any = [];

  constructor(
      private elRef: ElementRef,
      private VidModalWarningService: VidModalWarningService,
      private vidModalServiceError: VidModalErrorService,
      private headerService: HeaderService,
      private vidsignerApiService: ApiService,
      private translate: TranslateService,
      private router: Router
  ) {}

  description = '';
  title = 'general.errorModal';
  ngOnInit() {
    this.documents = this.vidsignerApiService.documents;
    this.VidModalWarningService.openModal.subscribe(val => {
      val.title ? this.title = val.title : '';
      this.description = val.description;
      this.elRef.nativeElement.classList.add('show');
    });

    this.VidModalWarningService.closeModal.subscribe(val => {
      this.close();
    });
  }

  close() {
    this.title = 'general.errorModal';
    this.elRef.nativeElement.classList.remove('show');
  }

  sendSMS() {
    this.sending = true;
    this.vidsignerApiService.singleDocClicked = false;
    this.headerService.hideAllButtonsWithoutLogo();
    if (this.vidsignerApiService.otpSended || this.vidsignerApiService.skipSMS) {
      this.elRef.nativeElement.classList.remove('show');
      this.sending = false;
      this.goToNextPage();
    } else {
      this.vidsignerApiService.putEmailSignatureOTP().subscribe(val => {
            this.elRef.nativeElement.classList.remove('show');
            this.sending = false;
            this.vidsignerApiService.otpSended = true;
            this.goToNextPage();
          },
          error => {
            this.elRef.nativeElement.classList.remove('show');
            this.sending = false;
            this.vidsignerApiService.otpSended = false;
            if (error.status === 500) {
              this.elRef.nativeElement.classList.remove('show');
              // Special case - Phone is not available to be reached by OTP SMS. Redirect directly to sign page and throws an error there.
              //this.showHeaderButtons();
              this.vidModalServiceError.open(
                  this.translate.instant(
                      'sms.sendingError',
                      { phoneNumber: this.documents[0].SignerDTO.PhoneNumber }
                  )
              );
            } else {
              this.elRef.nativeElement.classList.remove('show');
              //this.showHeaderButtons();
              this.vidModalServiceError.open(error.message);
            }
          });
    }

  }

  goToNextPage(id = 0) {
    let link = [`/documents/${id}/sms`];
    if (this.vidsignerApiService.otp || this.vidsignerApiService.skipSMS) {
      link = [`/documents/${id}/sign`];
    }
    this.router.navigate(link);
  }

}
