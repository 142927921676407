import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
const { detect } = require('detect-browser');
import { ApiService } from './core/services/api.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styles: [':host{overflow:hidden; min-height: 100%; }']
})
export class AppComponent implements OnInit {
  title = 'ViDSigner';
  lang = 'es';
  constructor(
      private translate: TranslateService,
      private activatedRoute: ActivatedRoute,
      private vidsignerApiService: ApiService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(this.lang);
    translate.use(this.lang);
    this.consoleMessage();
  }

  /**
   * Al inicializar, a no ser que se configure por parametro de URL, se configura el idioma por defecto.
   */
  ngOnInit() {
    const browser = detect();
    this.vidsignerApiService.browser = JSON.stringify(browser);
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['lang'] !== this.lang && params['lang']) {
        this.lang = params['lang'];
        this.translate.use(this.lang);
      }
    });
  }

  /**
   * Mensaje Coorporativo en la consola.
   */
  consoleMessage() {
    const base22MainStyle = `font-size:34px; font-weight:500; letter-spacing:0.02em;line-height:1.4em;
                            font-family:helvetica,arial; color:rgba(0,0,0,0.9);`;
    const base22MessageStyle = 'font-size:15px; font-weight:200; letter-spacing:0.2em; line-height:1.4em; font-family:helvetica,arial';
    console.log('%cVIDsigner', base22MainStyle);
    console.log('%cPowered by Validated ID', base22MessageStyle);
  }
}
