import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class VidBlockContentModule {

    constructor() {}

    openModal = new EventEmitter();
    closeModal = new EventEmitter();

    open (description, title?) {
        this.openModal.emit({
            description: description,
            title: title
        });
    }

    close () {
        this.closeModal.emit(true);
    }
}
