export const TYPES_COMPONENTS = {
  CHECKBOX : 'checkbox',
  RADIOBUTTON : 'radiobutton',
  TEXTBOX : 'textbox',
};
export function  objectKeysToLowerCase(comp) {
    Object.keys(comp).map((key: string) => {
      if (key.toLocaleLowerCase() !== key) {
        comp[key.toLocaleLowerCase()] = comp[key];
        delete comp[key];
        if (isObject(comp[key.toLocaleLowerCase()])) {
            comp[key.toLocaleLowerCase()]  = objectKeysToLowerCase(comp[key.toLocaleLowerCase()] );
        }

      }
    });
    return comp;
}

export function isObject (item) {
    return (typeof item === 'object' && !Array.isArray(item) && item !== null);
}

export function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function transformFormData(form) {
  form = parseFirstStructure(form);
  const result = form.map( val => {
      const page = val.map( group => {

        // simply object and parse attrs to lowercase
        group.components = group.components.map(comp => {

          comp = objectKeysToLowerCase(comp);
          comp.title = comp.title.text;
          // comp.disable = checkDisabled(comp, group.components);
          comp.disable = false;
          comp.error = false;
          if (comp.type === TYPES_COMPONENTS.RADIOBUTTON) {
            comp.choices = comp.choices.map(choices => choices.Title.Text);
            comp.selectedchoice = undefined;
          }
          if (comp.type === TYPES_COMPONENTS.CHECKBOX) {
            comp.response = undefined;
          }
          if (comp.type === TYPES_COMPONENTS.TEXTBOX) {
            comp.response = comp.response.Text;
          }
          return comp;
        });

        return group;
      });
      return page;
    });
    return result;
}

export function parseFirstStructure(form) {
  const result = form.map( val => {
    const page = val.Groups.map( group => {

      // add Type to object
      group.CheckBoxes = group.CheckBoxes ? Object.assign([], group.CheckBoxes).map(check => {
        check.type = TYPES_COMPONENTS.CHECKBOX ; return check;
      }) : [];
      group.RadioButtons = group.RadioButtons ? [...group.RadioButtons].map(check => {
        check.type = TYPES_COMPONENTS.RADIOBUTTON; return check;
      }) : [];
      group.TextBoxes = group.TextBoxes ? [...group.TextBoxes].map(check => {
        check.type = TYPES_COMPONENTS.TEXTBOX; return check;
      }) : [];

      var groupTitle = "";
      if(group.Title!=undefined)
      groupTitle = group.Title.Text;
      // join title page with components
      const obj = {
        title : groupTitle,
        components : [].concat(group.CheckBoxes, group.RadioButtons, group.TextBoxes)
      };
      return obj;
    });
    return page;
  });
  return result;
}

export function checkDisabled(comp, form) {

  if (comp.condition === null) {
    return false;
  } else {
    const resComponent = [];
    form.map( groups => {
      groups.map( group => {
        const aux = group.components.filter( val => val.id === comp.condition.id)[0];
        if (aux) {
          resComponent.push(aux);
        }
      });
    });
    let res = false;
    if (resComponent.length > 0) {
      if (resComponent[0].type === TYPES_COMPONENTS.RADIOBUTTON) {
        res = resComponent[0].selectedchoice !== comp.condition.result-1;
      } else {
        res = resComponent[0].response === false || resComponent[0].response === '';
      }
    }
    return res;

  }
}
