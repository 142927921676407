import { TranslateService } from '@ngx-translate/core';
import { CONSTANTS } from '../../app.constants';
import { ApiService } from '../../core/services/api.service';
import {  ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  public emailId;
  public percentage = 0;
  public error = '';
  public loading = false;
  public notShowHelperAfter;
  public documents = null;
  constructor(private activatedRoute: ActivatedRoute,
              private vidsignerService: ApiService,
              private router: Router,
              private translate: TranslateService,
              private http: HttpClient
            ) {
    // intenta coger la localizaión al inicializar la apliación.
    this.vidsignerService.updateLocation();
    this.notShowHelperAfter = localStorage.getItem(CONSTANTS.NOT_SHOW_HELP_STRING);
  }

  /**
   * Al iniciar coge el id del email por parametro de la URL y intenta cargar los datos asociados.
   */
  ngOnInit() {
    this.percentage = 20;
    this.error = '';
    this.vidsignerService.restartParameters();
    this.activatedRoute.queryParams.subscribe(params => {
      this.emailId = params['emailid'];
      this.vidsignerService.redirectUrl = params['VID_RETURN_URL'];
      this.vidsignerService.urlReturn = params['url_return'];
      var state = params['state'];
      var code = params['code'];
      /**Flujo de Bank ID */
      if (typeof state !== "undefined" && typeof code !== "undefined"){
        // el emailid viene del state
        var stateb64 = atob(state);
        var emailId = stateb64.split(",");
        this.vidsignerService.emailId = emailId[0];
        this.percentage = 40;
        this.vidsignerService.getOauthEmailToken(emailId[0]).subscribe( response => {
              this.percentage = 60;
              this.loading = true;
              this.vidsignerService.sendBankIdCode(state, code).subscribe(val => {
                //usar el signatureguid para acabar el proceso de firma
                //cargar los documentos pendientes getUnsignedDocumentByEmailId
                //saber cuál es el current doc a partir del signatureguid
                this.vidsignerService.getUnsignedDocumentByEmailId('').subscribe(documents => {
                  this.percentage = 90;
                  if (documents.length > 0) {
                    var lastDoc = documents[0]
                    if (this.vidsignerService.documents.length === 1) {
                      this.vidsignerService.documentsSignersArray.push(lastDoc.SignerDTO.SignerGUI);
                    }
                    this.vidsignerService.vidSignerDocument = lastDoc;
                    this.vidsignerService.setLangFiles();
                    // Send email clicked evidence
                    this.vidsignerService.postSignatureEvidence('EMAIL_CLICKED').subscribe(val => {
                      var link = [`/documents/0/sign`];
                      /**Redirección a selección de imágenes de DNI si IdDocument es true en el documento y además es bankID*/
                      if (this.vidsignerService.documents[0].SignerDTO.AttachIdDocImages === true){
                        link = [`/documents/0/IdDocument`];
                      }
                      this.percentage = 100;
                      setTimeout(() => {
                        this.loading = false;
                        this.router.navigate(link);
                      }, 2000);
                    }, e => {
                      this.router.navigate(['/document-error']);
                    });
                  } else {
                    this.router.navigate(['/document-error']);
                  }
                });
              }, e => {
                console.log('sendBankIdCode error')
                console.log(e)
                this.router.navigate(['/document-error']);
                this.error = 'Error in BankId process';
              });
            },
            e => {
              console.log('Invalid URL')
              console.log(e)
              this.router.navigate(['/document-error']);
              this.error = 'Invalid URL';
            });
      }
      /**No es Bank ID */
      else {
        this.percentage = 40;
        if (this.emailId) {
          this.loadDataEmail();
        } else {
          this.error = 'Invalid URL';
        }
      }
    });
  }

  /**
   * Carga los datos a partir del id del email.
   * Si hay documentos envia la evidencia de que ha entrado el email y te envia al tutorial o a la lista de documentos.
   * Si no hay documentos te envia a la pagina de error.
   */
  loadDataEmail() {
    this.percentage = 50;
    this.vidsignerService.getOauthEmailToken(this.emailId).subscribe( response => {
      this.percentage = 60;
      this.loading = true;
      this.vidsignerService.getUnsignedDocumentByEmailId('').subscribe(documents => {
        this.percentage = 90;
        if (documents.length > 0) {
          this.documents = documents;
          this.vidsignerService.setLangFiles();
          // Send email clicked evidence
          if (this.vidsignerService.isBankId === false){
            this.vidsignerService.postSignatureEvidence('EMAIL_CLICKED').subscribe(val => {
              const link = this.notShowHelperAfter ? [`/documents`] : [`/home`];
              this.percentage = 100;
              setTimeout(() => {
                this.loading = false;
                this.router.navigate(link);
              }, 2000);
            }, e => {
              const link = this.notShowHelperAfter ? [`/documents`] : [`/home`];
              this.router.navigate(link);
            });
          }
        else if (this.vidsignerService.isBankId === true) {
            const link = this.notShowHelperAfter ? [`/documents`] : [`/home`];
            this.percentage = 100;
            setTimeout(() => {
              this.loading = false;
              this.router.navigate(link);
            }, 2000);
          }
        } else {
          this.router.navigate(['/document-error']);
        }
      });
    },
    e => {
      this.router.navigate(['/document-error']);
      this.error = 'Invalid URL';
    });
  }


}
